/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */


/* Core CSS required for Ionic components to work properly */

@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */

@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "./theme/variables.scss";

ion-tab-bar {
    padding-bottom: var(--ion-safe-area-bottom, 16px);
}

.color {
    color: var(--ion-color-primary);
}

.discussion-question .modal-wrapper {
    --max-width: 300px;
    --max-height: 250px;
    ion-title {
        padding: 0;
    }
    .header-ios ion-toolbar {
        padding-top: 5px;
    }
    border-radius: 5px;
}

.feedback .modal-wrapper {
    ion-title {
        padding: 0;
    }
    textarea {
        border: 1px solid #ccc;
    }
    .header-ios ion-toolbar {
        padding-top: 5px;
    }
    // border-radius: 5px;
}

ion-alert.manual-entry .alert-input-wrapper:last-child:before {
    content: 'SLAC-';
    display: inline-block;
    width: 17%;
}

ion-alert.manual-entry input#delegate_ID {
    width: 80%;
}

h1.page-title {
    font-size: 32px;
}

p {
    font-weight: 500;
}

.ion-full-width {
    width: 100%;
}

.ion-text-italic {
    font-style: italic;
}

.ion-text-bold {
    font-weight: bold;
}

ion-modal.fullscreen {
    --width: 100%;
    --height: 100%;
    --border-radius: 0;
}

ion-modal.media-player app-media-player {
    overflow-y: scroll;
}

ion-button.text-danger {
    --background: var(--ion-color-white);
    --color: var(--ion-color-danger);
    --background-activated: var(--ion-color-danger);
}

ion-button.text-danger.ion-activated {
    --color: var(--ion-color-white);
}

ion-button.text-danger.button-outline {
    --border-color: var(--ion-color-danger);
}

ion-card-content+ion-card-header {
    padding-top: 0;
}

ion-content .header-collapse-condense ion-toolbar {
    --background: none;
}

ion-card-content.card-body {
    padding-top: 0;
    p {
        font-size: 16px;
        line-height: 1.6rem;
    }
}

ion-card-content.multiple-paragraphs {
    p {
        color: var(--color);
        font-size: 16px;
        margin-bottom: 1rem;
    }
}
ion-list.ion-no-padding-vertical {
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 10px
}
ion-item-group.item-separate,
ion-list.item-separate {
    ion-item-divider {
        background: none;
        color: var(--ion-color-dark);
        h1 {
            font-weight: bold;
        }
        h2 {
            color: var(--ion-color-medium);
        }
        h3 {
            color: var(--ion-color-medium-shade);
            text-transform: uppercase;
            font-weight: bold;
            font-size: 14px;
        }
    }
    background: none;
    ion-list-header {
        background: none !important;
    }
    ion-item {
        margin: 15px 15px;
        box-shadow: 0px 2px 15px 0px var(--ion-item-box-shadow);
        border-radius: 25px;
        border-right: 20px solid var(--ion-color-medium);
        ion-label {
            p {
                font-weight: bold;
            }
        }
    }
    ion-item::part(native) {
        padding: 10px 0px;
    }
    .avatar-wrapper {
        margin-inline: 10px;
        margin-top: 2px;
        margin-bottom: 2px;
        display: flex;
        flex-flow: column;
        gap: 5px;
        position: relative;
        ion-avatar {
            width: 55px;
            height: 55px;
        }
        ion-badge {
            position: absolute;
            right: -10px;
            bottom: -10px;
        }
    }
    ion-avatar.icon {
        width: 55px;
        height: 55px;
        background: var(--ion-avatar-background);
        padding: 13px;
        margin-inline: 10px 10px;
        border-radius: 35%;
        ion-img {
            border-radius: 0;
        }
        ion-img::part(image) {
            object-fit: scale-down;
        }
    }
    ion-icon {
        background: var(--ion-icon-background);
        padding: 13px;
        margin-inline: 10px 10px;
        border-radius: 35%;
        font-size: 29px;
        margin-top: 2px;
        margin-bottom: 2px;
        color: var(--ion-color-dark);
    }
    ion-img {
        max-width: 30px;
        height: auto;
    }
    ion-badge {
        font-size: 1rem;
        color: var(--ion-color-medium);
        --background: var(--ion-badge-background);
    }
    .ion-item-date {
        margin-inline-start: 15px;
        margin-inline-end: 0px;
    }
}

.item-separate.item-radios {
    ion-radio {
        margin-inline: 15px;
    }
}

small {
    margin: 2rem auto;
    display: block;
}

ion-segment-button {
    min-width: fit-content;
    overflow: initial;
    --indicator-color: var(--ion-color-primary);
    border-radius: 0;
}

ion-datetime {
    border-radius: 16px;
    margin: 1rem auto;
}

.table-responsive {
    overflow: scroll;
    ion-row {
        flex-wrap: nowrap;
    }
}

/* Material Design styles */

ion-segment-button.md {
    --color: var(--ion-color-black);
    --color-checked: var(--ion-color-primary);
    --indicator-height: 4px;
}


/* iOS styles */

ion-segment-button.ios {
    --color-checked: #fff;
}

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.embed-responsive {
    position: relative;
    display: block;
    height: 0;
    padding: 0;
    overflow: hidden;
}

.embed-responsive-16by9 {
    padding-bottom: 56.25%;
}

.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 0px;
}

@media (prefers-color-scheme: dark) {
    ion-item {
        --background: var(--ion-card-background) !important;
        p {
            color: var(--ion-color-light-contrast) !important;
        }
    }
    table,
    ion-list-header:not(.ion-color-clear) {
        background: var(--ion-card-background) !important;
    }
}

body.scanner-active {
    --background: transparent;
    --ion-background-color: transparent;
  }

  .media-link {
    display: inline-block;
    align-items: center;
    padding: 3px 8px;
    text-transform: uppercase;
    font-size: 9pt;
    margin: 3px;
    border-radius: 4px;
    text-decoration: none;
    border: 2px solid var(--ion-color-primary);
    ion-img {
        max-width: 11px;
        display: inline-block;
        vertical-align: middle;
    }
}

ion-item[hidden],
ion-card[hidden],
ion-list[hidden],
.hidden {
    display: none !important;
}